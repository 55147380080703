import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2ae14feb"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalDisplayModals: require('/Users/stefan/Dropbox/Stefan/gitrepos/p1ng/gnoema/frontend/components/global/display/Modals.vue').default})
